<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="b-container">
      <div class="con-l">
        <div class="mod1 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">新亨镇介绍</span>
          </div>
          <div class="top">
            <img class="xinh" src="https://home/ResImg/xinhz.png" alt="" />
            <div class="txt">
              新亨镇，隶属于广东省揭阳市揭东区，地处揭东县西北部。东南连埔田镇、锡场镇，南接月城镇，西南邻桂岭镇，西北、北毗邻玉湖镇，东北与梅州市丰顺县留隍镇，潮州市潮安区登塘镇交界
              总面积95.26平方千米。<br />
              南宋绍兴十四年（1144年），杭金名将韩世忠巡视揭阳县境西北部，为当时揭阳知县祁罗撰写的对联中有“方圆鼓，敲上更楼，太阳升矣。溢溪鱼，跳过新亨，化龙去也。”之句。此为名称来历之一说；又传朝廷新任官员往兴梅一带上任，必经新亨官道，寓新仕亨达通途，故新亨有“万里通亨”之门。“新亨”一名即出于此。1983年10月，改为新亨区。1987年3月，新亨区改镇。1991年12月归属揭东县。截至2020年6月，新亨镇共下辖1个社区、13个行政村。
            </div>
          </div>
          <div class="swiper-container swiper mySwiper1">
            <div class="b-pub-tt flex">
              <span class="bt">十三村</span>
            </div>
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-slide1">
                <p>楼下村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/louxia.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>秋江村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/qiujiang.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>北良村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/beiliang.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>坪埔村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/pingpu.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>硕和村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/shuohe.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>硕联村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/shuolian.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>白石村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/baishi.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>硕榕村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/shuorong.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>五房村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/wufang.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>下坝村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/xiaba.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>仙美村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/xianmei.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>溢溪村</p>
                <img
                  src="https://media.shouxinfund.com/jieyang/yixi.jpg"
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <p>英花村</p>
                <img src="https://home/ResImg/yinghuacun.jpg" alt="" />
              </div>
            </div>
          </div>
          <ul class="mod1-gk">
            <li>
              <img src="../assets/images/wt3.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">常住人口</h6>
                <p class="num" style="color: #489ffe">12.08万</p>
              </div>
            </li>
            <li>
              <img src="../assets/images/wt4.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">土地总面积</h6>
                <p class="num" style="color: #85b1ff">95.26平方千米</p>
              </div>
            </li>
            <li>
              <img src="../assets/images/wt4.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">水域面积</h6>
                <p class="num" style="color: #90ffad">12.39平方公里</p>
              </div>
            </li>
            <li>
              <img src="../assets/images/wt7.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">湖岸线总长</h6>
                <p class="num" style="color: #ffc55a">28.2公里</p>
              </div>
            </li>
            <li>
              <img src="../assets/images/wt7.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">年平均气温</h6>
                <p class="num" style="color: #45f5ff">16度-22度</p>
              </div>
            </li>
            <li>
              <img src="../assets/images/wt7.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">年平均湿度</h6>
                <p class="num" style="color: #45f5ff">79%</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="mod-cen mod3">
          <div class="cen-menu">
            <span class="lk">智慧城市</span>
            <span class="lk">数字乡村振兴</span>
            <span class="lk">新亨电商</span>
            <span class="lk cur">智慧文旅</span>
          </div>
          <div class="cen-top">
            <li>
              <h6 class="tit">月累计游客(人)</h6>
              <p class="num">{{ mVisitor }}</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">年累计游客(人)</h6>
              <p class="num">{{ yVisitor }}</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">人均消费(元/人)</h6>
              <p class="num">{{ personCon }}</p>
            </li>
          </div>
          <div class="mod3-map">
            <div class="swiper-container swiper-container1">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img
                    src="https://media.shouxinfund.com/jieyang/wucha.jpg"
                    alt=""
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://media.shouxinfund.com/jieyang/lantian.jpg"
                    alt=""
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://media.shouxinfund.com/jieyang/jinainbei.jpg"
                    alt=""
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://media.shouxinfund.com/jieyang/xinhengz.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <ul class="mod3-bot">
            <li>
              <img src="../assets/images/wlv_17.png" class="icon" alt="" />
              <span>景区景点</span>
            </li>
            <li>
              <img src="../assets/images/wlv_18.png" class="icon" alt="" />
              <span>交通接驳</span>
            </li>
            <li>
              <img src="../assets/images/wlv_19.png" class="icon" alt="" />
              <span>住宿</span>
            </li>
            <li>
              <img src="../assets/images/wlv_20.png" class="icon" alt="" />
              <span>餐饮</span>
            </li>
            <li>
              <img src="../assets/images/wlv_21.png" class="icon" alt="" />
              <span>公共服务</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="con-r flex flex-v">
        <div class="mod4 flex-1 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">景区设施</span>
          </div>
          <div class="flex flex-pack-justify flex-1">
            <div class="detail">
              <div class="col col1">
                <div class="b-smtit">
                  <span class="bt b-smtit1">酒店</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container2">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/jieyang/jiudian.jpg"
                          alt=""
                        />
                      </div>
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/jieyang/jiudian.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col2">
                <div class="b-smtit">
                  <span class="bt b-smtit1">餐饮</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container5">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/home.png" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/home.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col3">
                <div class="b-smtit">
                  <span class="bt b-smtit1">交通</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container6">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/jiaotong.png" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="https://home/ResImg/jiaotong.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col4">
                <div class="b-smtit">
                  <span class="bt b-smtit1">公共服务</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container3">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/jieyang/fuwu.jpg"
                          alt=""
                        />
                      </div>
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/jieyang/fuwu.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col5">
                <div class="b-smtit">
                  <span class="bt b-smtit1">住房</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container4">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/jieyang/food.jpg"
                          alt=""
                        />
                      </div>
                      <div class="swiper-slide">
                        <img
                          src="https://media.shouxinfund.com/jieyang/food.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 50%; height: 1px"></div>
            </div>
          </div>
        </div>
        <div class="mod6">
          <div class="b-pub-tt flex">
            <span class="bt">年度游客来源占比排行</span>
          </div>
          <div class="flex flex-pack-justify">
            <div class="col">
              <div class="b-smtit">
                <span class="bt">景区酒店客流排名</span>
              </div>
              <ul class="bar-list">
                <li class="row" v-for="(item, index) in jqList" :key="index">
                  <span class="num">{{ index + 1 }}</span>
                  <div class="smbox">
                    <div class="font font1">
                      <div>{{ item.name }}</div>
                      <div class="flex flex1">
                        <span>{{ item.num1 }}人</span>
                      </div>
                    </div>
                    <div class="progress">
                      <i :style="{ width: item.widthVar }"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col">
              <div class="b-smtit">
                <span class="bt">景区餐饮排行</span>
              </div>
              <ul class="bar-list">
                <li class="row" v-for="(item, index) in jqList1" :key="index">
                  <span class="num">{{ index + 1 }}</span>
                  <div class="smbox">
                    <div class="font font1">
                      <span>{{ item.name }}</span>
                      <span>{{ item.num2 }}万元</span>
                    </div>
                    <div class="progress">
                      <i :style="{ width: item.widthVar }"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Head from "@/components/head_big.vue";
let Echarts = require("echarts/lib/echarts");
import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  data() {
    return {
      headTitle: "",
      // 月游客
      mVisitor: "",
      // 人均消费
      personCon: "",
      // 年度游客
      yVisitor: "",
      swiper1: [],
      swiper2: [],
      swiper3: [],
      swiper4: [],
      swiper5: [],
      swiper6: [],
      mySwiper1: [],
      mySwiper2: [],
      jqList: [],
      jqList1: [],
      chart1: null,
      tootipTimer1: "",
      chart2: null,
      tootipTimer2: "",
      chart3: null,
      tootipTimer3: "",
      chart4: null,
      tootipTimer4: "",
    };
  },
  components: {
    Head,
  },
  computed: {},
  created() {},
  mounted() {
    // 获取智慧文旅的信息
    this.getwenlvData();
    this.swiper1 = new Swiper(".swiper-container.swiper-container1", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      speed: 3000,
      slidesPerView: 1,
      spaceBetween: -10,
      slidesPerColumn: 1,
      autoplay: 10000,
      loop: true,
      centeredSlides: true,
      slidesOffsetAfter: 100,
    });
    this.swiper2 = new Swiper(".swiper-container.swiper-container2", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper3 = new Swiper(".swiper-container.swiper-container3", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper4 = new Swiper(".swiper-container.swiper-container4", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper5 = new Swiper(".swiper-container.swiper-container5", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper6 = new Swiper(".swiper-container.swiper-container6", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.mySwiper1 = new Swiper(".swiper-container.mySwiper1", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      slidesPerView: 4,
      spaceBetween: 20,
      // slidesPerColumn: 2,
      speed: 3000,
      autoplay: true,
      loop: true,
    });
    window.addEventListener("resize", () => {
      // this.chart1.resize();
      // this.chart2.resize();
      this.chart3.resize();
      this.chart4.resize();
    });
  },

  methods: {
    // 获取智慧文旅的信息
    getwenlvData() {
      const res = axios
        .get("/10zhwl.json", {
          params: {
            // timestamp:new Date().getTime()
            random: Math.random(),
          },
        })
        .then(
          (res) => {
            console.log("请求成功了", res.data);
            this.headTitle = res.data.bigTitle;
            this.mVisitor = res.data.mVisitor;
            this.personCon = res.data.personCon;
            this.yVisitor = res.data.yVisitor;
            this.jqList = res.data.hotelList;
            this.jqList1 = res.data.caterList;
            for (let i = 0; i < this.jqList.length; i++) {
              if (this.jqList[i].num1.indexOf("万人")) {
                this.jqList[i].num1 =
                  this.jqList[i].num1.replace("万人", "0000") * 10000;
                console.log(this.jqList[0].num1);
              }
            }

            this.jqList1 = res.data.caterList;
            // for (let m = 0; m < this.jqList1.length; m++) {
            //   if (this.jqList1[m].num2.indexOf("万元")) {
            //     this.jqList1[m].num2 =
            //       this.jqList1[m].num2.replace("万元", "0000") * 10000;
            //   }
            //   console.log(this.jqList1[m].num2);
            // }
          },
          (err) => {
            console.log("请求失败了", err.message);
          }
        );
    },
  },
};
</script>

<style scoped>
.page {
  background: url(https://home/ResImg/wlv_22.png) no-repeat center;
  background-size: 100% 100%;
  background-color: #0a224b;
}

.mod1 {
  margin-bottom: 15px;
  height: 100vh;
}
/* .mod1 .mySwiper1{
  height: 70vh;
}
.mod1 .mySwiper1  .swiper-slide{
  width: 100%;
  height: 70vh;
} */
.mod1 .top {
  /* height: 55%; */
  display: flex;
}
.mod1 .top .xinh {
  border: 5px solid #15386e;
  border-radius: 20px;
  display: block;
  width: 50%;
}
.mod1 .top .txt {
  /* margin-top: 10%; */
  width: 50%;
  height: 100%;
  color: #fff;
  margin-left: 20px;
  font-size: 22px;
}
.mod1 .swiper {
  width: 100%;
  height: 100%;
}
.mod1 .swiper-slide1 {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;

  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-direction: column;
}
.mod1 .swiper-slide1 p {
  font-size: 36px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
}
.swiper-slide1 img {
  display: block;
  width: 100%;
  object-fit: cover;
}
.mod1 .mySwiper2 {
  position: relative;
  height: 80%;
  width: 100%;
}
.mod1 .mySwiper1 {
  margin-top: 5%;
  height: 40%;
  box-sizing: border-box;
  padding: 10px 0;
}
.mod1 .mySwiper1 .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 1;
}
.mod1 .mySwiper1 .swiper-slide-thumb-active {
  opacity: 1;
}
.mod1 .mySwiper1 .swiper-wrapper {
  height: auto;
}
.mod1 .mySwiper2 .swiper-slide {
  position: relative;
}
.mod1 .mySwiper2 .swiper-slide img {
  border: 5px solid #15386e;
  border-radius: 30px;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 98%;
  height: 60%;
}
.mod1 .mySwiper2 .swiper-slide .txt {
  margin-top: 3%;
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 37%;
  font-size: 32px;
  color: #fff;
  letter-spacing: 2px;
}
/* .mod1 .detail {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}
.mod1 .detail .icon1 {
  margin-top: 8%;
  width: 100%;
  height: 25%;
}
.mod1 .detail img {
  width: 100%;
  height: 300px;
}
.mod1 .list {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  min-height: 8vh;
  align-items: center;
}
.mod1 .list li {
  display: flex;
  align-items: center;
}
.mod1 .list .icon {
  width: 100px;
  margin-right: 10px;
}
.mod1 .list .info {
  flex: 1;
  overflow: hidden;
}
.mod1 .list .tit {
  font-size: 24px;
  color: #fff;
}
.mod1 .list .num {
  font-size: 28px;
  color: #fff;
}
.mod1 .chart {
  width: 100%;
  height: calc(100% - 50px);
} */
.mod2 {
  height: 55vh;
}
.mod2 .list {
  height: 55vh;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mod2 .list .item {
  width: 48%;
  height: 22.5vh;
  height: calc(50% - 15px);
}
.mod2 .list .item:nth-child(1),
.mod2 .list .item:nth-child(2) {
  margin-bottom: 20px;
}
.mod2 .list .chart {
  width: 100%;
  height: calc(100% - 50px);
}

.mod2 .fenbu {
  width: 100%;
  height: calc(100% - 50px);
}

.con-c {
  position: relative;
}

.mod3-bot {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
  padding: 0 4%;
  z-index: 10;
}
.mod3-bot li {
  display: flex;
  align-items: center;
}
.mod3-bot .icon {
  width: 44px;
  margin-right: 16px;
}
.mod3-bot li span {
  font-size: 26px;
  color: #fff;
  font-family: "RuiZiZhenYan";
  letter-spacing: 3px;
}

.mod3-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}
.mod3-map .swiper-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 100%;
  height: 60%;
  /* background-color: #15386e; */
  overflow: hidden;
  /* margin-top: 50px; */
  width: 100%;
}
.mod3-map .swiper-container .swiper-slide .swiper-wrapper {
  margin: 0 auto;
}
.mod3-map .swiper-container .swiper-slide {
  width: 80%;
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  /* border: 5px solid;
    border-image: linear-gradient(163deg, #1D60D2 4%, #BCDEFF 42%, rgba(6,12,59,0.80) 86%) 50; */
  transform: scale(1);
}
.mod3-map .swiper-container .swiper-slide img {
  width: 80%;
  height: 80%;
  border-radius: 120px 120px 120px 120px;
}
.mod4 {
  height: 70%;
  padding-bottom: 15px;
  /* max-height: 70vh; */
}
.mod4 .detail {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.mod4 .col {
  width: 50%;
  height: 23%;
  /* display: flex;
  flex-direction: column; */
}
/* 改变 */
.mod4 .col .b-smtit1 {
  width: 150px;
  display: block;
  margin: 0 auto;
}
/* 改变 */
.mod4 .swiper-container {
  /* width: 300px;
  height: 150px; */
  width: 100%;
  height: 80%;
}
.mod4 .detail .swiper-container .swiper-slide {
  /* width: 300px;
  height: 150px; */
  width: 100%;
  height: 160px;
  border-radius: 20px;
  border: 5px solid #15386e;
}
.mod4 .detail .swiper-container .swiper-slide img {
  /* width: 300px;
  height: 150px; */
  border-radius: 20px;
  width: 100%;
  height: 160px;
}
.mod4 .col1 .bar-list .font1,
.mod4 .col2 .bar-list .font1 {
  display: flex;
  justify-content: space-around;
}
.mod4 .list {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  grid-gap: 15px;
}
.mod4 .list .item {
  position: relative; /* width: 185px;height: 160px; */ /* margin:0 0 25px 15px; */
}
.mod4 .list .title {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #15386e;
  padding: 5px 5px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

/* .mod5 {
  height: 14vh;
}
.mod5 .b-pub-tt {
  margin-bottom: 10px;
}
.mod5 .list {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}
.mod5 .list li {
  display: flex;
  align-items: center;
}
.mod5 .list .xh {
  width: 100px;
  margin-right: 5px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PangMenZhengDao";
  font-size: 28px;
  color: #fff;
}
.mod5 .list .info {
  flex: 1;
  overflow: hidden;
}
.mod5 .list .tit {
  font-size: 22px;
  color: #fff;
}
.mod5 .list .num {
  font-size: 26px;
  color: #fff;
  font-family: "PangMenZhengDao";
  margin-top: 5px;
}

.mod5 .list .item1 .xh {
  background-image: url(../assets/images/wlv_13.png);
}
.mod5 .list .item2 .xh {
  background-image: url(../assets/images/wlv_14.png);
}
.mod5 .list .item3 .xh {
  background-image: url(../assets/images/wlv_15.png);
}
.mod5 .list .item4 .xh {
  background-image: url(../assets/images/wlv_16.png);
}
.mod5 .list .item5 .xh {
  background-image: url(../assets/images/wlv_23.png);
} */

.mod6 {
  /* margin-top: -50%; */
  height: 30%;
  margin-bottom: 2%;
}
.mod6 .col {
  width: 46%;
}
.mod6 .col .bar-list .smbox .font1 {
  display: flex;
  justify-content: space-between;
}
.mod6 .num {
  width: 16px;
  text-align: center;
  color: #fd9d20;
}
.mod1 .nhjj {
  height: 70%;
  width: 100%;
}
.mod1-gk {
  display: flex;
  flex-wrap: wrap;
}
.mod1-gk li {
  width: 33%;
  display: flex;
  align-items: center;
}
.mod1-gk .icon {
  width: 120px;
  height: 120px;
}
.mod1-gk .info {
  flex: 1;
  overflow: hidden;
}
.mod1-gk .tit {
  font-size: 20px;
  color: #fff;
}
.mod1-gk .num {
  margin-top: 2%;
  font-size: 26px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
}
</style>
